import { Environment } from '@rxap/environment';

export const environment: Environment = {
  name: 'production',
  production: true,
  app: 'service-server',
  serviceWorker: true,
  sentry: {
    dsn: 'https://bcb55533e7f34ebdb8c3baaa04096555@sentry.eurogard.cloud/7',
    enabled: true,
    debug: false,
  },
};
